import * as React from "react"
import ClassInternal from "../components/class-internal"
import ChangePassword from "../components/change-password"
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute"
import Layout from "../components/layout"
import Seo from "../components/seo"


const ChangePasswordPage = () => (
  <Layout  pageName="Change Password">
    <Seo title="Change Password" />
    <Router>
      <PrivateRoute path="/change-password" component={ChangePassword}/>
    </Router>
  </Layout>
)

export default ChangePasswordPage

import { Button, Container, Grid, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import styles from '../assets/scss/edit-profile.module.scss'
import GridRow from './_grid-row';
import UserContext from '../providers/user-provider'


export default function ChangePassword() {

    const [invalidForm, setInvalidForm] = useState(true);
    const [personal, setPersonal] =  useState({
   
        old_password: '',
        password: '',
        repeat_password: '',
    });

    const [formDirtyFields, setFormDirtyFields] = useState({
 
        old_password: false,
        password: false,
        repeat_password: false,
      
    });

    const helperText = {
     
        old_password: 'Please enter your current password.',
        password: 'Password must be at least 8 characters.',
        repeat_password: 'Password doesn\'t match.'
    }



    function handleTextField(e) {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        if (
            value === undefined ||
            value == '' ||
            (name == 'repeat_password' && !isPasswordMatch(value))
        ) {
            setInvalidForm(true);
            setFormDirtyFields({ ...formDirtyFields, [name]: true });

        } else {
            setInvalidForm(false);
            setFormDirtyFields({ ...formDirtyFields, [name]: false });
            personal[name] = value;
            
        }

        // if (name == "password" && !isPasswordMatch(value)) {
        //     setFormDirtyFields({ ...formDirtyFields, repeat_password: true });
        //     setInvalidForm(true);
        // } else {
        //     setFormDirtyFields({ ...formDirtyFields, repeat_password: false });
        // }
    }


    function isPasswordMatch(pass) {
        if (pass === personal.password) {
            return true
        }

        return false
    }

    function submit(e) {
        e.preventDefault();

        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if (fieldValidation || personal[key] == undefined || personal[key] == '') {
                    isFormValid = false;
                    setFormDirtyFields({ ...formDirtyFields, [key]: true });
                    break;
                }
            }

            isFormValid = true;
        }

        if (isFormValid) {
            // updateFormData('personal', personal);
            // handleNext();
        }


        return false;
    }
    console.log('page re-loaded!')
    return (
        <React.Fragment>
            <div className={`editProfile ${styles.wrapper}`}>
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3} direction="row-reverse">

                            <Grid item xs={12}>
                                <form autoComplete="off" onSubmit={submit}>

                                    <div className={styles.formField}>
                                        <TextField
                                            label="Old Password"
                                            type="password"
                                            name="old_password"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.password}
                                            helperText={formDirtyFields.password ? helperText.password : ''}
                                            required
                                        />
                                    </div>
                                    <div className={styles.formField}>
                                        <TextField
                                            label="New Password"
                                            type="password"
                                            name="password"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.password}
                                            helperText={formDirtyFields.password ? helperText.password : ''}
                                            required
                                        />
                                    </div>
                                    <div className={styles.formField}>
                                        <TextField
                                            label="Confirm Password"
                                            type="password"
                                            name="repeat_password"
                                            variant="outlined"
                                            classes={{ root: styles.input }}
                                            onChange={handleTextField}
                                            error={formDirtyFields.repeat_password}
                                            helperText={formDirtyFields.repeat_password ? helperText.repeat_password : ''}
                                            required
                                        />
                                    </div>

                                    <div className={styles.formField}>
                                        <Button disabled={invalidForm} variant="contained" color="primary" disableElevation type="submit" classes={{ root: styles.btn }}>Update</Button>
                                    </div>
                                </form>

                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </div>

        </React.Fragment>
    )
}

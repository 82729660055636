import React, { useContext } from "react"
import { navigate } from "gatsby"
import UserContext from "../providers/user-provider"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const {user, isLoading, setRedirectUrl} = useContext(UserContext);
  if (!user && !isLoading && location.pathname !== '/signin') {
    if(typeof window !== 'undefined') {
        // setUser(false);
      setRedirectUrl(location.pathname)
      navigate("/signin");
    }
      
    return null
  } 
  

  return <Component {...rest} />
}

export default PrivateRoute